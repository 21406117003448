@import './styles/colors.scss';

.thumb {
  border: none !important;
}

.thumb.selected {
  border: solid 2px $white !important;
}

.thumbs-wrapper {
  display: flex !important;

  & > ul {
    margin: auto;
  }
}

.carousel.carousel-slider {
  max-width: 668px;
  outline: 1px solid $white;
  overflow: visible !important;
}

.legend {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 1em !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(to top, $grey, transparent) !important;
  border: none !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  opacity: 1 !important;
}

.slide {
  & > div {
    width: 100%;
    height: 100%;
    display: inline-flex;
    & > img {
      object-fit: cover !important;
      max-height: 660px;
    }
  }
}

/* hide thumbnails on mobile */
@media only screen and (max-width: 768px) {
  .thumbs-wrapper {
    display: none !important;
  }
}

.thumb {
  max-height: 64px;
}
