@import './colors.scss';
@import './variables.scss';

@font-face {
  font-family: Carnevalee;
  font-style: normal;
  src: url('/fonts/Carnevalee Freakshow.ttf') format('opentype');
}

body {
  font-family: 'Lato', sans-serif;
  color: $white;
}

h1 {
  font-family: Carnevalee, sans-serif;
  color: $white;
  margin: 0;
  letter-spacing: 10px;
  font-size: 46px;

  @media #{$desktop-medium} {
    font-size: 52px;
  }
}

h2 {
  font-family: Carnevalee, sans-serif;
  color: $white;
  letter-spacing: 4px;
  margin: 0;
  font-size: 36px;

  @media #{$desktop-medium} {
    font-size: 46px;
  }
}

h3 {
  font-family: 'Lato', sans-serif;
  color: $white;
  letter-spacing: 2px;
  margin: 0;
  font-weight: 700;
  font-size: 24px;

  @media #{$desktop-medium} {
    font-size: 28px;
  }
}

h4 {
  font-family: 'Lato', sans-serif;
  color: $white;
  letter-spacing: 2px;
  margin: 0;
  font-weight: 700;
  font-size: 18px;

  @media #{$desktop-medium} {
    font-size: 20px;
  }
}

h5 {
  font-family: 'Lato', sans-serif;
  color: $white;
  letter-spacing: 2px;
  margin: 0;
  font-weight: 700;
  font-size: 16px;

  @media #{$desktop-medium} {
    font-size: 18px;
  }
}

p {
  letter-spacing: 1.2px;
}

.uppercase {
  text-transform: uppercase;
}
