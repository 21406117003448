@import './typography.scss';
@import './colors.scss';
@import './variables.scss';
@import './carousel.scss';

html,
body {
  height: 100%;
  padding: 0;
  line-height: 1.3;
  margin: 0;
  font-size: 1.05em;
  font-family: 'Lato', sans-serif;
  background: $grey;
  scroll-behavior: smooth;
}

.hide-scroll {
  overflow: hidden !important;
}

::placeholder {
  font-weight: 300;
}

a {
  color: $yellow;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100%;
}

#map {
  height: 100%;
}
